import { ComponentProps } from 'react';

import { IconSystem } from '@/icons/IconSystem';

import { Link } from './Link';
import styles from './LinkWithChevron.module.scss';

export function LinkWithChevron({ className = '', children, ...rest }: ComponentProps<typeof Link>) {
	return (
		<Link className={`${className} ${styles.linkWithChevron} underline-hover`} {...rest}>
			<IconSystem icon="next" variant="filled" className={styles.chevron} aria-hidden={true} />
			{children}
		</Link>
	);
}
