import Image from 'next/image';

import { Link } from '@/components/Link/Link';

import { appLinks } from '../Footer.utils';

import styles from './AppSection.module.scss';
import { Section } from './Section';

type Props = { className?: string };

export function AppSection({ className }: Props) {
	return (
		<Section title="BR24-App downloaden" expandable={false} className={className}>
			<div className={styles.section}>
				{appLinks.map(({ icon, title, url }, index) => {
					return (
						<Link href={url} target="_blank" key={index} className="outline-inside">
							<Image src={icon} alt={title} unoptimized={true} />
						</Link>
					);
				})}
			</div>
		</Section>
	);
}
