import { ApolloClient, ApolloProvider } from '@apollo/client';
import { UsercentricsProvider } from '@s-group/react-usercentrics';
import { ThemeProvider } from 'next-themes';
import { ReactNode } from 'react';

import { BreakpointMediaContextProvider } from '../breakpoints/media';

import { Environment, EnvironmentProvider } from './EnvironmentContext';
import { MediaContextProvider } from './MediaContext';
import { TrackingProvider } from './TrackingProvider';

type Props = {
	children: ReactNode;
	apolloClient: ApolloClient<unknown>;
	environment: Environment;
};

const ContextProvider = ({ children, apolloClient, environment }: Props) => {
	return (
		<EnvironmentProvider environment={environment}>
			<ApolloProvider client={apolloClient}>
				<BreakpointMediaContextProvider>
					<TrackingProvider>
						<MediaContextProvider>
							<ThemeProvider disableTransitionOnChange={true}>
								<UsercentricsProvider windowEventName="ucEvent">{children}</UsercentricsProvider>
							</ThemeProvider>
						</MediaContextProvider>
					</TrackingProvider>
				</BreakpointMediaContextProvider>
			</ApolloProvider>
		</EnvironmentProvider>
	);
};

export default ContextProvider;
