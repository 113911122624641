import Image from 'next/image';
import { memo } from 'react';

import { Link } from '@/components/Link/Link';

import styles from './Logo.module.scss';
import LogoFile from './icons/Logo.svg';

type Props = {
	className?: string;
};

export const Logo = memo(function Logo({ className = '' }: Props) {
	return (
		<Link className={`${styles.homeLink} ${className} outline-inside`} title="zur Startseite" href="/">
			<Image src={LogoFile} alt="BR24 Logo | Startseite öffnen" unoptimized={true} />
		</Link>
	);
});
