import formatInteger from '../integer/formatInteger';

type BuildSectionPlacementVariableInput = {
	inSectionPlacement?: number | null;
	sectionPlacement?: number | null;
};

export const buildSectionPlacementVariable = ({ inSectionPlacement, sectionPlacement }: BuildSectionPlacementVariableInput) => {
	if (typeof inSectionPlacement !== 'number' || typeof sectionPlacement !== 'number') {
		return null;
	}

	const sectionPlacementVariable = `${formatInteger(sectionPlacement, 0, 2)}-${inSectionPlacement}`;

	return sectionPlacementVariable;
};
