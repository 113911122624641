import { PropsWithChildren, useRef } from 'react';

import { TrackEvent, EventVariant } from '@/types/tracking';
import { TracktorProvider } from 'react-tracktor';

import { TrackingRegistry } from '../tracking/TrackingRegistry';
import { GoogleTagManager } from '../tracking/google-tag-manager/GoogleTagManagerCustom';

import { useEnvironment } from './EnvironmentContext';

type Props = PropsWithChildren;

export function TrackingProvider({ children }: Props) {
	const { keys } = useEnvironment();

	const registryRef = useRef<TrackingRegistry | null>(null);
	if (!registryRef.current) {
		registryRef.current = new TrackingRegistry(keys.GOOGLE_TAG_MANAGER_ID);
		registryRef.current.register('gtm');
	}

	const dispatcher = (trackingData: TrackEvent<EventVariant>) => {
		if (registryRef.current) {
			registryRef.current.onEvent(trackingData);
		}
	};

	return (
		<>
			<GoogleTagManager gtmOrigin="pa.br.de" gtmId={keys.GOOGLE_TAG_MANAGER_ID} />
			<TracktorProvider dispatcher={dispatcher}>{children}</TracktorProvider>
		</>
	);
}
