import * as breakpoints from '@/shared/styles/breakpoints';
import { Breakpoint } from '@/types/enums';

function getMediaQuery(breakpoint: Breakpoint) {
	return `only screen and (min-width: ${breakpoints[breakpoint]}px)` as const;
}

export const mediaQueries = {
	sm: getMediaQuery('sm'),
	md: getMediaQuery('md'),
	lg: getMediaQuery('lg'),
	xl: getMediaQuery('xl'),
};
