import { usePathname } from 'next/navigation';

import { LinkWithChevron } from '@/components/Link/LinkWithChevron';

import { Link } from '../Link/Link';
import TopArticles from '../TopArticles';

import styles from './ErrorGone.module.scss';
import { ErrorLayout } from './ErrorLayout';

export function ErrorGone() {
	const pathname = usePathname();

	const isHomePage = pathname === '/';

	return (
		<ErrorLayout>
			<div className={`heading1 ${styles.titlePrimary}`}>Fehler 410 – Seite nicht mehr verfügbar</div>

			<section className={`body1 ${styles.section}`}>
				<p>An was kann das liegen?</p>

				<ul className={styles.unorderedList}>
					<li>Die Seite wurde depubliziert.</li>
					<li>
						Wir können den Beitrag leider online nicht mehr anbieten, weil die{' '}
						<Link
							className={styles.styledLink}
							href="https://www.br.de/unternehmen/inhalt/organisation/rundfunkrat/rr-drei-stufen-test-verweildauer-daserste-100.html"
							target="_blank"
						>
							„Verweildauer“
						</Link>{' '}
						abgelaufen ist.
					</li>
				</ul>

				{!isHomePage && (
					<li className={styles.listItem}>
						<LinkWithChevron href="/">
							<span className="heading3">Zur BR24 Startseite</span>
						</LinkWithChevron>
					</li>
				)}
			</section>

			<section className={styles.section}>
				<TopArticles />
			</section>
		</ErrorLayout>
	);
}
