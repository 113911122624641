import { useKeyboardEvent } from '@react-hookz/web';
import Image from 'next/image';
import { useRef, useEffect, ChangeEvent, useCallback, useId } from 'react';

import { useFocusInside } from '@/cutils/hooks/useFocusInside';

import { RadioButtonIcon } from './RadioButtonIcon';
import styles from './ThemeSelector.module.scss';
import ThemeDarkIcon from './icons/ThemeDarkIcon.svg';
import ThemeLightIcon from './icons/ThemeLightIcon.svg';
import ThemeSystemIcon from './icons/ThemeSystemIcon.svg';

const OptionsKeyboardEvents = new Set(['ArrowLeft', 'ArrowRight', 'Home', 'End']);

const Themes = [
	{
		image: <Image className={styles.radioImage} src={ThemeLightIcon} alt="Helles Farbschema" unoptimized={true} />,
		name: 'Hell',
		value: 'light' as const,
		ariaLabel: 'Helles Farbschema',
	},
	{
		image: <Image className={styles.radioImage} src={ThemeDarkIcon} alt="Dunkles Farbschema" unoptimized={true} />,
		name: 'Dunkel',
		value: 'dark' as const,
		ariaLabel: 'Dunkles Farbschema',
	},
	{
		image: <Image className={styles.radioImage} src={ThemeSystemIcon} alt="Systemvorgabe" unoptimized={true} />,
		name: 'System',
		value: 'system' as const,
		ariaLabel: 'Systemvorgabe',
	},
];

type Props = {
	className?: string;
	theme: string | undefined;
	onThemeChange?: (theme: string) => void;
	forceTheme?: 'light' | 'dark';
};

export function ThemeSelector({ className, theme, onThemeChange, forceTheme }: Props) {
	const themeSelectorRef = useRef(null);
	const optionsRef = useRef<(HTMLLabelElement | null)[]>([]);
	const id = useId();

	const selectedThemeIndex = Themes.findIndex(({ value }) => value === theme);

	const themeSelectorIsFocused = useFocusInside(themeSelectorRef);

	useKeyboardEvent(
		(event) => {
			// handle keyboard events only if theme selector ist focused
			return themeSelectorIsFocused && OptionsKeyboardEvents.has(event.code);
		},
		(e) => {
			const lastChildIndex = Themes.length - 1;
			let themeToActivate = selectedThemeIndex;
			switch (e.code) {
				case 'ArrowLeft':
					themeToActivate = Math.max(selectedThemeIndex - 1, 0);
					break;
				case 'ArrowRight':
					themeToActivate = Math.min(selectedThemeIndex + 1, lastChildIndex);
					break;
				case 'Home':
					themeToActivate = 0;
					break;
				case 'End':
					themeToActivate = lastChildIndex;
					break;
			}

			onThemeChange?.(Themes[themeToActivate].value);
			e.stopPropagation();
			e.preventDefault();
		},
		[themeSelectorIsFocused, selectedThemeIndex, onThemeChange]
	);

	useEffect(() => {
		if (themeSelectorIsFocused) {
			optionsRef.current[selectedThemeIndex]?.focus();
		}
	}, [selectedThemeIndex, themeSelectorIsFocused]);

	const changeTheme = useCallback(
		(e: ChangeEvent<HTMLInputElement>) => {
			onThemeChange?.(e.target.value);
		},
		[onThemeChange]
	);

	return (
		<ul className={`${className ?? ''} ${styles.themeSelector}`} ref={themeSelectorRef} data-theme={forceTheme}>
			{Themes.map(({ value, image, name, ariaLabel }, index) => {
				const isSelected = selectedThemeIndex === index;

				const radioId = `${id}-${value}`;

				return (
					<li className={styles.themeItem} key={value}>
						<label
							htmlFor={radioId}
							className={isSelected ? `${styles.selected} body1` : ''}
							tabIndex={isSelected ? 0 : -1}
							ref={(label) => {
								optionsRef.current[index] = label;
							}}
							aria-label={ariaLabel}
						>
							{image}
							<input
								type="radio"
								value={value}
								id={radioId}
								name="theme"
								checked={isSelected}
								onChange={(e) => changeTheme(e)}
								tabIndex={-1}
							/>
							<RadioButtonIcon className={styles.checkbox} selected={isSelected} />
							<span className={styles.radioLabel}>{name}</span>
						</label>
					</li>
				);
			})}
		</ul>
	);
}
