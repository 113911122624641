import { type LoggerArgument } from '@sep/br-logging';
import stringify from 'safe-json-stringify';

const LOG_LEVELS = ['warn', 'info', 'error', 'debug'] as const;

type Logger = { [K in (typeof LOG_LEVELS)[number]]: (...args: LoggerArgument[]) => void };
export type LogLevel = (typeof LOG_LEVELS)[number];

let loggerInternal: Logger;

/*
 * When the code runs on the server we use the backend log infrastructure for logging.
 * Because we need to conditionally import the logger we fall back to require statements
 * since otherwise dynamic imports would lead to each log statement to be asynchronous.
 */
if (typeof window === 'undefined') {
	// eslint-disable-next-line @typescript-eslint/no-require-imports
	const { getSpanLogger }: typeof import('./span-logger') = require('./span-logger');

	const LOG_LEVELS = ['warn', 'info', 'error', 'debug'] as const;

	function logServer(level: LogLevel) {
		return (...args: LoggerArgument[]) => {
			const logger = getSpanLogger();

			logger[level](...args);
		};
	}

	loggerInternal = LOG_LEVELS.reduce((acc, level) => {
		Object.defineProperty(acc, level, { value: logServer(level) });
		return acc;
	}, {} as Logger);
} else {
	// we don't use configServerEnv, because it can not be imported in client side code
	const isProduction = process.env.NODE_ENV === 'production';

	function logClient(level: LogLevel) {
		return (...args: LoggerArgument[]) => {
			// In production, we don't wont to log to console,
			// but send the error to the server to use the `brLogger`, so that we can see them in `kibana`.
			if (isProduction) {
				if (level === 'error') {
					// only report error messages during production
					fetch('clientError', {
						method: 'POST',
						headers: { 'Content-Type': 'application/json' },
						body: JSON.stringify({ level, message: stringify(args) }),
					});
				}
			} else {
				// eslint-disable-next-line no-console
				console[level](...args);
			}
		};
	}

	loggerInternal = LOG_LEVELS.reduce((acc, level) => {
		Object.defineProperty(acc, level, { value: logClient(level) });
		return acc;
	}, {} as Logger);
}

export const logger = loggerInternal;
