import { ComponentProps, FormEvent, useRef, useState } from 'react';
import { Popover } from 'react-tiny-popover';

import { Tooltip } from '@/components/base/Tooltip/Tooltip';
import { logger } from '@/utils/logging/logger';
import { IconSystem } from 'src/client/icons/IconSystem';

import styles from './NewsletterSection.module.scss';
import { Section } from './Section';

type Response = {
	message: string;
	success: boolean;
};

async function subscribeToNewsletter(formData: FormData): Promise<Response> {
	try {
		const response = await fetch('newsletter/subscribe', {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(Object.fromEntries(formData)),
		});

		if (response.ok) {
			const data = await response.json();

			return {
				success: data.success || false,
				message: data.message || '',
			};
		} else {
			try {
				if ((response.headers.get('content-type')?.indexOf('application/json') ?? -1) > -1) {
					const data = await response.json();

					logger.error('Newsletter Error', data);

					return {
						message: data.message,
						success: false,
					};
				}
			} catch (e: any) {
				logger.error('Newsletter Error', e);
			}
		}
	} catch (error: any) {
		logger.error('Newsletter Error', error);
	}
	return {
		message: 'Unbekannter Fehler bei der Newsletter Anmeldung',
		success: false,
	};
}

type TooltipState = ComponentProps<typeof Tooltip>;

type Props = {
	className?: string;
};

export function NewsletterSection({ className }: Props) {
	const [isPending, setPending] = useState(false);
	const [isFormVisible, setFormVisible] = useState(true);
	const [tooltipState, setTooltipState] = useState<TooltipState & { visible: boolean }>({
		content: '',
		type: 'info',
		visible: false,
	});
	const emailInputRef = useRef<HTMLInputElement>(null);

	const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		if (isPending) {
			return;
		}

		// check if input is valid
		const emailValid = !!emailInputRef.current?.validity.valid;
		if (!emailValid) {
			setTooltipState({
				content: 'Ihre Eingabe muss mit dem geforderten Format übereinstimmen z.B.: max.mustermann@br.de',
				type: 'info',
				visible: true,
			});
			return;
		} else {
			setTooltipState({
				content: '',
				type: 'info',
				visible: false,
			});
		}

		const data = new FormData(e.target as any);
		setPending(true);
		const result = await subscribeToNewsletter(data);
		if (result.success) {
			setTooltipState({
				content: 'Anmeldebestätigung verschickt',
				type: 'success',
				visible: true,
			});
			setFormVisible(false);
		} else {
			setTooltipState({
				content: result.message,
				type: 'error',
				visible: true,
			});
		}
		setPending(false);
	};

	const { visible: tooltipVisible, ...tooltipProps } = tooltipState;

	return (
		<Section title="BR24 Newsletter" expandable={false} className={className}>
			<p>Zum Feierabend das Wichtigste aus der BR24 Redaktion.</p>
			<Popover
				isOpen={tooltipVisible}
				positions={['bottom', 'top']}
				align="start"
				content={({ childRect }) => {
					return (
						<Tooltip
							style={{
								width: childRect.width,
							}}
							{...tooltipProps}
						/>
					);
				}}
			>
				<div className={styles.formWrapper}>
					{isFormVisible && (
						<form className={`${styles.form} ${isPending ? styles.pending : ''} heading4`} onSubmit={(e) => onSubmit(e)} noValidate={true}>
							<input
								className={styles.input}
								type="email"
								name="email"
								placeholder="E-Mail eingeben"
								aria-label="E-Mail eingeben"
								ref={emailInputRef}
							></input>
							<button type="submit" className={styles.button} disabled={isPending}>
								<IconSystem icon="chevron-right" variant="filled" title="Beim Newsletter anmelden" />
							</button>
						</form>
					)}
				</div>
			</Popover>
		</Section>
	);
}
