import React, { PropsWithChildren, ReactElement, cloneElement } from 'react';

import styles from './SkipLink.module.scss';

type Props = PropsWithChildren<{
	className?: string;
	/**
	 * The css query aiding the selection of the
	 * container (main, section etc) we want to scroll to;
	 */
	skipTo?: string;

	children: ReactElement;
}>;

export function SkipLink({ className = `${styles.skipLink} outline-inside`, children, skipTo = 'main:first-of-type' }: Props) {
	const onClick = (event: React.SyntheticEvent) => {
		event.preventDefault();

		const container: HTMLElement | null = document.querySelector(skipTo);

		if (container) {
			container.tabIndex = -1;
			container.focus();
			setTimeout(() => container.removeAttribute('tabindex'), 1000);
		}
	};

	return cloneElement(children, { onClick, className: className });
}
