import { Link } from '@/components/Link/Link';
import { IconSystem } from 'src/client/icons/IconSystem';

import { socialMediaLinks } from './Footer.utils';
import styles from './Social.module.scss';

type Props = {
	className?: string;
};

export function Social({ className = '' }: Props) {
	return (
		<section className={`${styles.social} ${className}`}>
			<ul className={styles.iconWrapper}>
				{socialMediaLinks.map(({ url, icon, title }, index) => {
					return (
						<li key={index}>
							<Link href={url} target="_blank" className={`${styles.socialLink} no-focus`}>
								<IconSystem icon={icon} variant="filled" size="l" title={title} />
							</Link>
						</li>
					);
				})}
			</ul>
		</section>
	);
}
