import { deepmerge } from '@/cutils/merge/deepmerge';
import { logger } from '@/utils/logging/logger';

import { TrackingData } from '../types';

// Types

const computeTrackingData = (...args: TrackingData[]) => {
	try {
		return deepmerge(...args) as any;
	} catch (err: any) {
		logger.error(err);

		throw Error(
			"Couldn't parse `trackingData`. Did you try to pass `trackEvent` to an `onClick` handler directly? Try `onClick={() => trackEvent({})` instead of `onClick={trackEvent}`."
		);
	}
};

export default computeTrackingData;
