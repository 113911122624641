import { HTMLAttributes, memo } from 'react';

import styles from './Icon.module.scss';

/**
 * Describes the available icon sizes.
 *
 * `sm`: 16px
 * `s`: 20px
 * `m`: 24px
 * `l`: 32px
 * `xl`: 48px
 */
type IconSize = 'xs' | 's' | 'm' | 'l' | 'xl';

export type IconPropsWithVariant<IconVariants extends Record<string, string>, IconName extends keyof IconVariants> = {
	icon: IconName;
	spriteSrc: string;
	variant: IconVariants[IconName];
	size?: IconSize;
} & HTMLAttributes<SVGElement>;

export type IconPropsWithoutVariant<IconVariants extends Record<string, string>, IconName extends keyof IconVariants> = Omit<
	IconPropsWithVariant<IconVariants, IconName>,
	'variant'
>;

function IconWithVariantsComponent<IconVariants extends Record<string, string>, IconName extends keyof IconVariants>({
	icon,
	variant,
	size = 'm',
	className,
	spriteSrc,
	...svgProps
}: IconPropsWithVariant<IconVariants, IconName>) {
	return (
		// this is necessary to prevent hydration matches for react-tiny-popover wrapped components
		<svg suppressHydrationWarning={true} className={`${styles[size]} ${className ?? ''}`} {...svgProps}>
			<use href={`${spriteSrc}#${icon as string}_${variant}`} />
		</svg>
	);
}

/**
 * Generic component to displays icon from sprites generated by the `build-icon-sprite` script with variants support.
 *
 * @param props
 */
export const IconWithVariants = memo(IconWithVariantsComponent) as typeof IconWithVariantsComponent;

function IconWithoutVariantsComponent<IconVariants extends Record<string, string>, IconName extends keyof IconVariants>({
	icon,
	size = 'm',
	className,
	spriteSrc,
	...svgProps
}: IconPropsWithoutVariant<IconVariants, IconName>) {
	return (
		<svg className={`${styles[size]} ${className ?? ''}`} {...svgProps}>
			<use href={`${spriteSrc}#${icon as string}`} />
		</svg>
	);
}

/**
 * Generic component to displays icon from sprites generated by the `build-icon-sprite` script without variants support.
 *
 * @param props
 */
export const IconWithoutVariants = memo(IconWithoutVariantsComponent) as typeof IconWithoutVariantsComponent;
