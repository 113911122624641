import { useKeyboardEvent } from '@react-hookz/web';
import { Children, PropsWithChildren, useEffect, useMemo, useRef, useState } from 'react';

import { useFocusInside } from '@/cutils/hooks/useFocusInside';

import styles from './Tabs.module.scss';

const TabBarKeyboardEvents = new Set(['ArrowLeft', 'ArrowRight', 'Home', 'End']);

type Props = PropsWithChildren<{ className?: string }>;

export function Tabs({ children, className }: Props) {
	const [activeTab, setActiveTab] = useState(0);
	const tablistRef = useRef<HTMLDivElement>(null);
	const tabButtonsRef = useRef<(HTMLButtonElement | null)[]>([]);

	const tabs = useMemo(() => Children.toArray(children).filter((child) => !!child), [children]);

	// for focus handling see https://www.w3.org/WAI/ARIA/apg/patterns/tabs/
	const tabListIsFocused = useFocusInside(tablistRef);
	useKeyboardEvent(
		(event) => {
			// handle keyboard events only if tab list ist focused
			return tabListIsFocused && TabBarKeyboardEvents.has(event.code);
		},
		({ code }) => {
			const lastChildIndex = Math.max(tabs.length - 1, 0);
			let tabToActivate = activeTab;
			switch (code) {
				case 'ArrowLeft':
					tabToActivate = Math.max(activeTab - 1, 0);
					break;
				case 'ArrowRight':
					tabToActivate = Math.min(activeTab + 1, lastChildIndex);
					break;
				case 'Home':
					tabToActivate = 0;
					break;
				case 'End':
					tabToActivate = lastChildIndex;
					break;
			}

			setActiveTab(tabToActivate);
		},
		[tabListIsFocused, activeTab, tabs]
	);

	useEffect(() => {
		if (tabListIsFocused) {
			tabButtonsRef.current[activeTab]?.focus();
		}
	}, [activeTab, tabListIsFocused]);

	const handleTabActivate = (tabNumber: number) => setActiveTab(tabNumber);

	return (
		<div className={className}>
			<div className={styles.tabList} role="tablist" ref={tablistRef}>
				{Children.map(tabs, (child, index) => {
					const isActiveTab = activeTab === index;

					return (
						<button
							id={`tab-${index}`}
							className="button-link no-focus"
							type="button"
							role="tab"
							aria-selected={isActiveTab}
							aria-controls={`tabpanel-${index}`}
							onClick={() => handleTabActivate(index)}
							tabIndex={isActiveTab ? 0 : -1}
							ref={(element) => {
								tabButtonsRef.current[index] = element;
							}}
						>
							{(child as any).props.icon}
							<span className={`${styles.tabButtonLabel} heading4`}>{(child as any).props.label}</span>
							{index === activeTab ? <div className={styles.activeTabLine}></div> : null}
						</button>
					);
				})}
			</div>
			<div className={styles.tabContainer}>
				{Children.map(tabs, (child, index) => {
					return (
						<div
							className={`${styles.tabContent} ${activeTab === index ? styles.tabActive : ''}`}
							id={`tabpanel-${index}`}
							role="tabpanel"
							// focus the content of the tab instead of the tab itself
							tabIndex={-1}
							aria-labelledby={`tab-${index}`}
						>
							{child}
						</div>
					);
				})}
			</div>
		</div>
	);
}
