import { ComponentPropsWithoutRef, ElementType, PropsWithChildren, useId } from 'react';

import { IconSystem } from '@/icons/IconSystem';

import styles from './Banner.module.scss';

type Props = PropsWithChildren<{
	as?: ElementType;
	onClose?: () => void;
	noCloseButton?: boolean;
}> &
	ComponentPropsWithoutRef<ElementType>;

export function Banner({ as: As = 'div', children, onClose, noCloseButton, className = '', ...rest }: Props) {
	const buttonId = useId();

	return (
		<As className={`${styles.wrapper} ${className}`} {...rest}>
			<section className={`${styles.content} body7`}>{children}</section>

			{!noCloseButton && !!onClose && (
				<button className={styles.closeButton} onClick={onClose} aria-label="Banner schließen" id={buttonId}>
					<IconSystem icon="close" variant="filled" aria-labelledby={buttonId} />
				</button>
			)}
		</As>
	);
}
