import { FocusTrap, FocusTrapProps } from 'focus-trap-react';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useEnvironment } from '@/cutils/context/EnvironmentContext';
import { useBreakpoint } from '@/cutils/hooks/useBreakpoint';
import { useOnClickOutside } from '@/cutils/hooks/useOnOutsideClick';
import { NavigationList as NavigationListType, NavigationTree } from '@/cutils/page-context/navigation';
import { buildNavigationStructuredData } from '@/cutils/seo/schema-org/buildNavigationStructuredData';

import { impressumLinksForStructuredData, mediaLinks, rubrikenLinks } from '../Footer/Footer.utils';

import { BannerNoJavaScript } from './Banner/BannerNoJavaScript';
import { BannerOutdatedBrowserContainer } from './Banner/BannerOutdatedBrowser';
import styles from './Header.module.scss';
import { HeaderNavigation } from './HeaderNavigation';
import { LoadingBar } from './LoadingBar';
import { MainNavigation } from './MainNavigation';
import { NavigationList } from './NavigationList/NavigationList';
import { NavigationListLinkItem } from './NavigationList/NavigationListItem';
import { SkipLink } from './SkipLink';
import { useImperativeDisableScroll } from './use-imperative-disable-scroll';

const BannerAndroidApp = dynamic(() => import('./Banner/BannerAndroidApp'), {
	ssr: false,
});

type Props = {
	navigation: {
		rubriken: NavigationTree;
		bayern: NavigationListType;
		highlight: NavigationListType;
	};
	navigationTitle: string;
};

export function Header({ navigation, navigationTitle }: Props) {
	const [mainMenuOpen, setMainMenuOpen] = useState(false);
	const [bayernMenuOpen, setBayernMenuOpen] = useState(false);
	const [isAndroid, setIsAndroid] = useState(false);
	const menuButtonRef = useRef<HTMLButtonElement | undefined>(undefined);
	const bayernButtonRef = useRef<HTMLButtonElement | undefined>(undefined);
	const mainNavigationRef = useRef<HTMLElement>(null);
	const bayernNavigationRef = useRef<HTMLUListElement>(null);
	const router = useRouter();
	const isDesktop = useBreakpoint('lg');
	const { constants } = useEnvironment();

	const closeMenuOverlays = useCallback(() => {
		setMainMenuOpen(false);
		setBayernMenuOpen(false);
	}, []);

	// disable scrolling on main body if menu is open
	useImperativeDisableScroll(typeof window !== 'undefined' ? document.body : undefined, !isDesktop && mainMenuOpen);

	// hide menu overlays on route change
	useEffect(() => {
		router.events.on('routeChangeStart', closeMenuOverlays);

		return () => {
			router.events.off('routeChangeStart', closeMenuOverlays);
		};
	}, [closeMenuOverlays, router.events]);

	const focusTrapOptions = useMemo(() => {
		return {
			// close menu when focus trap is deactivated
			onPostDeactivate: closeMenuOverlays,
			// allow outside clicks to interact with the remainder of the page
			// closing is handled via the outside click hook
			allowOutsideClick: true,
		} satisfies FocusTrapProps['focusTrapOptions'];
	}, [closeMenuOverlays]);

	const handleMainMenuButtonClick = useCallback(() => {
		setMainMenuOpen(!mainMenuOpen);
	}, [mainMenuOpen]);

	const handleBayernMenuButtonClick = useCallback(() => {
		setBayernMenuOpen(!bayernMenuOpen);
	}, [bayernMenuOpen]);

	useOnClickOutside(mainNavigationRef, closeMenuOverlays, isDesktop && mainMenuOpen, [menuButtonRef.current]);
	useOnClickOutside(bayernNavigationRef, closeMenuOverlays, isDesktop && bayernMenuOpen, [bayernButtonRef.current]);

	useEffect(() => {
		setIsAndroid(/Android/i.test(navigator.userAgent));
	}, []);

	const showBayernMenu = navigation.bayern.length > 0;

	return (
		<>
			<LoadingBar />
			<header className={`${styles.header} heading4 outline-inside`} data-theme-area="dark">
				<SkipLink>
					<button className="heading4" type="button">
						Navigation überspringen
					</button>
				</SkipLink>
				<HeaderNavigation
					highlightNavigation={navigation.highlight}
					navigationTitle={navigationTitle}
					menuOpen={mainMenuOpen}
					onBayernMenuClick={handleBayernMenuButtonClick}
					onMainMenuClick={handleMainMenuButtonClick}
					menuButtonRef={menuButtonRef}
					bayernButtonRef={bayernButtonRef}
					hideBayernMenu={!showBayernMenu}
				/>
				<FocusTrap active={mainMenuOpen} focusTrapOptions={focusTrapOptions}>
					<MainNavigation ref={mainNavigationRef} className={styles.mainMenu} menuOpen={mainMenuOpen} navigation={navigation} />
				</FocusTrap>
				{showBayernMenu ? (
					<FocusTrap active={bayernMenuOpen} focusTrapOptions={focusTrapOptions}>
						<NavigationList
							className={`${styles.bayernMenu} ${bayernMenuOpen ? styles.open : ''}`}
							ref={bayernNavigationRef}
							hidden={!bayernMenuOpen}
							inert={!bayernMenuOpen}
						>
							{navigation.bayern.map(({ link, id, title, isExternal }) => {
								return (
									<NavigationListLinkItem depth={0} href={link} key={id} target={isExternal ? '_blank' : undefined}>
										{title}
									</NavigationListLinkItem>
								);
							})}
						</NavigationList>
					</FocusTrap>
				) : null}
				{buildNavigationStructuredData(
					constants.appUrl,
					navigation.highlight,
					navigation.rubriken,
					navigation.bayern,
					rubrikenLinks,
					mediaLinks,
					impressumLinksForStructuredData
				)}
				<BannerOutdatedBrowserContainer />
				<BannerNoJavaScript />
				{isAndroid ? <BannerAndroidApp /> : null}
			</header>
		</>
	);
}
